import React from "react";
import { useParams, useNavigate } from "react-router";
// import Ainqaflexmon from "./ainqaflex";
// import Flexview from "ainqareport";
import { IconButton, Typography, Paper } from "@material-ui/core";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { AlertContext } from "./context/alert.context";
import axios from "axios";
import Flexview from "./components/flexView";
import Pdfviewer from "./pdfviewer";

// import { AinqaRequestSend } from "ainqa-request";
// import JSEncrypted from "./jsencrypt";

function Viewpage() {
  const id = useParams();
  const navigation = useNavigate();
  const alertMessage = React.useContext(AlertContext);

  const [state, setState] = React.useState({});
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    getReport();
    let token = sessionStorage.getItem("token");
    let tokekval = token.split(".")[1];
    let decoded = JSON.parse(atob(tokekval));
    setUser({
      name: decoded.name,
      email: decoded.email,
    });
    /*  console.log("decoded", {
      name: decoded.name,
      email: decoded.email,
    }); */

    //eslint-disable-next-line
  }, []);

  const getReport = async () => {
    try {
      let temp1 = {
        db_name: process.env.REACT_APP_DB_NAME,
        entity: process.env.REACT_APP_ENTITY,
        filter: `${process.env.REACT_APP_ENTITY}.reportid=='${id.id}'`,
        return_fields: process.env.REACT_APP_ENTITY,
      };

      let config1 = {
        method: "post",
        url: process.env.REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
        data: temp1,
      };

      await axios(config1).then((res) => {
        if (res.data.Code === 201) {
          let data = res.data.result[0];
          setState(data);
        } else {
          alertMessage.setAlert({
            ...alertMessage,
            open: true,
            message: "Something Went Wrong",
            severity: "error",
          });
        }
      });
    } catch (error) {
      alertMessage.setAlert({
        ...alertMessage,
        open: true,
        message: "Something Went Wrong",
        severity: "error",
      });
      console.log(error);
    }
  };

  // let data = {
  //   application: "clmm",
  // };

  /* let data = {
    queuename: "Clone Queue",
    activestatus: true,
  }; */

  let data = {
    Patient__id: "Patient/10116",
    Encounter__id: "Encounter/10271",
  };

  let env = {
    readUrl: process.env.REACT_APP_API_URL_READ,
    upsertUrl: process.env.REACT_APP_API_URL_UPSERT,
    projectdbname: sessionStorage.getItem("databasename"),
    dbname: process.env.REACT_APP_DB_NAME,
    readQdmQuery: process.env.REACT_APP_API_URL_QDMQueryREAD,
    idmUpsertUrl: process.env.REACT_APP_API_IdmServicesUpsert,
  };

  // console.log(Flexview);

  // let users = {};

  return (
    <div style={{ padding: "20px", height: "100%" }}>
      <Paper
        style={{
          width: "100%",
          margin: "auto",
          height: "100%",
          padding: "12px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0 16px",
            height: "42px",
          }}
        >
          <IconButton
            onClick={() => {
              navigation(-1);
            }}
          >
            <KeyboardBackspaceOutlinedIcon style={{ color: "black" }} />
          </IconButton>
          <Typography
            style={{
              fontWeight: "700",
              fontFamily: "Arial",
              margin: "3px",
              padding: "2px",
            }}
          >
            View Report
          </Typography>
        </div>
        <div style={{ padding: "0 16px", height: "calc(100% - 42px)" }}>
          {state?.reporttype === "superset" && (
            <iframe
              title="superset"
              src={state.url}
              style={{ height: "100%", width: "100%" }}
            ></iframe>
          )}
          {state?.reporttype === "flexmonster" && (
            // <Ainqaflexmon reportid={id.id} />
            <>
              <Flexview
                reportid={id.id}
                argument={data}
                view={true}
                env={env}
                userid={""}
              />
              {/* <JSEncrypted /> */}
            </>
          )}
          {state?.reporttype === "jasper" && <Pdfviewer data={state} />}

          {state?.reporttype === "queryreport" && (
            <Typography>NView for Query Report not configured yet!!</Typography>
          )}
        </div>
      </Paper>
    </div>
  );
}

export default Viewpage;
