import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./homepage";
import Stepform from "./stepper";
import Ainqaflexmon from "./ainqaflex";
import Stepperedit from "./stepperedit";
import Viewpage from "./Viewpage";
import axios from "axios";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import { Card, Stack } from "@mui/material";
import { AlertProvider } from "./context/alert.context";
import { NavBar } from "./components/navbar";
import { ContextThemeProvider } from "./context/theme.context";
import { CssBaseline, StylesProvider } from "@material-ui/core";
import { getRepoid, upsertRepo } from "./repoApiCalls";
function Home() {
  localStorage.setItem("reload", "");
  const [projectName, SetprojectName] = React.useState("");
  const [clientName, SetclientName] = React.useState("");
  const [pageerr, setPageerr] = React.useState(false);
  localStorage.setItem("url", window.location.search);
  let params = new URLSearchParams(window.location.search);
  console.log("awdadawd", localStorage.getItem("reload"));
  if (localStorage.getItem("reload") === "true") {
    console.log("true");
  }
  if (params.get("metadata_id")) {
    sessionStorage.setItem("metadataId", params.get("metadata_id"));
    sessionStorage.setItem("token", params.get("token"));
  }
  var metadataid = sessionStorage.getItem("metadataId");
  React.useEffect(() => {
    // window.location.reload(true);
    if (metadataid === null) {
      setPageerr(true);
    } else {
      setPageerr(false);
    }
    var datas = {
      db_name: process.env.REACT_APP_DB_NAME,
      entity: "projectvstools",
      filter: `projectvstools.metadataid =='${metadataid}'`,
      return_fields: "projectvstools",
    };

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL,
      header: { "content-Type": "application/json" },
      data: datas,
    };

    axios(config)
      .then(async (res) => {
        console.log("res");
        sessionStorage.setItem("clientid", res.data.result[0].clientid);
        sessionStorage.setItem("projectid", res.data.result[0].projectid);
        sessionStorage.setItem("databasename", res.data.result[0].dbname);
        await getIDMmetaId(res.data.result[0].projectid);

        var data1 = {
          db_name: process.env.REACT_APP_DB_NAME,
          entity: "clients",
          filter: `clients._id =='${sessionStorage.getItem("clientid")}'`,
          return_fields: "clients",
        };
        var config1 = {
          method: "post",
          url: process.env.REACT_APP_API_URL,
          header: { "content-Type": "application/json" },
          data: data1,
        };
        axios(config1).then((res) => {
          console.log(res.data.result[0].clientname);
          SetclientName(res.data.result[0].clientname);
          getParentRepo();
          // sessionStorage.setItem("clientname", res.data.result[0].clientname);
        });
        var data2 = {
          db_name: process.env.REACT_APP_DB_NAME,
          entity: "projects",
          filter: `projects._id =='${sessionStorage.getItem("projectid")}'`,
          return_fields: "projects",
        };

        var config2 = {
          method: "post",
          url: process.env.REACT_APP_API_URL,
          header: { "content-Type": "application/json" },
          data: data2,
        };
        axios(config2).then((res) => {
          console.log(res.data.result[0].projectname);
          SetprojectName(res.data.result[0].projectname);
          // sessionStorage.setItem("projectname", res.data.result[0].projectname);
        });
      })
      .catch((err) => {
        console.error(err);
      });

    //eslint-disable-next-line
  }, [metadataid]);

  const getParentRepo = async (idmid) => {
    if (params.get("metadata_id")) {
      let data = await getRepoid(params.get("metadata_id"));
      console.log("hasparent", data);

      if (data.length === 0) {
        let insertParentRepo = await upsertRepo(
          "Report Configurator",
          "",
          sessionStorage.getItem("IDMmetaId")
        );
        console.log(insertParentRepo);
        sessionStorage.setItem("parentrep", insertParentRepo.rep_id);
      } else {
        sessionStorage.setItem("parentrep", data[0].rep_id);
      }
    }
  };

  const getIDMmetaId = async (projectid) => {
    try {
      let params = {
        db_name: process.env.REACT_APP_DB_NAME,
        entity: "projectvstools",
        filter: `DOCUMENT(projectvstools.toolid).toolid=='089f927b-fef1-4f83-8f3f-941a300d05ff' and projectvstools.projectid =='${projectid}'`,
        return_fields: "projectvstools.metadataid",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          // console.log(res.data.result[0]);
          sessionStorage.setItem("IDMmetaId", res.data.result[0]);
        })
        .catch((err) => {
          // console.log(err);
          alert("Something Went Wrong");
        });
    } catch (error) {}
  };

  return (
    <>
      {pageerr ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            display: "flex",
            backgroundColor: "white",
          }}
        >
          <Card
            style={{
              width: "50%",
              height: "50%",
              margin: "auto",
              display: "flex",
              boxShadow: "0 0 15px white",
              borderRadius: "20px",
              backgroundColor: "rgba(206, 17, 38, 0.05)",
            }}
          >
            <Stack direction="row" spacing={10} alignItems="center">
              <ErrorIcon
                variant=""
                fontSize="large"
                color="error"
                style={{ margin: "auto 0 auto 15px" }}
              />

              <Typography
                color="gray"
                style={{
                  margin: "auto 0 auto 30px",
                  fontSize: "50px",
                  fontFamily: "serif",
                  fontStyle: "normal",
                  fontWeight: "20%",
                }}
              >
                500 something went wrong
              </Typography>
            </Stack>

            {/* <Button startIcon={<ArrowBackIosIcon fontSize="large"/>} size="medium" variant="contained" color="primary" onClick={()=>this.handleback()} ><Typography>GO back</Typography></Button> */}
          </Card>
        </div>
      ) : (
        <>
          <div style={{ height: "100vh" }}>
            {/* <div
              style={{
                display: "grid",
                gridTemplateColumns: "20px auto",
                margin: "0 0 20px 0",
                height: "64px",
              }}
            >
              <h2 style={{ color: "#0a78fa", margin: "auto 20px" }}>ATP</h2>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div>
                  <p
                    style={{
                      backgroundColor: "#0a78fa",
                      color: "white",
                      padding: "2px",
                      textAlign: "center",
                      margin: "5px",
                    }}
                  >
                    Client Name
                  </p>
                  <p style={{ margin: "0", textAlign: "center" }}>
                    {clientName}
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      backgroundColor: "#0a78fa",
                      color: "white",
                      padding: "2px",
                      textAlign: "center",
                      margin: "5px",
                    }}
                  >
                    Project Name
                  </p>
                  <p style={{ margin: "0", textAlign: "center" }}>
                    {projectName}
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      backgroundColor: "#0a78fa",
                      color: "white",
                      padding: "2px",
                      textAlign: "center",
                      margin: "5px",
                    }}
                  >
                    Database Name
                  </p>
                  <p style={{ margin: "0", textAlign: "center" }}>
                    {sessionStorage.getItem("databasename")}
                  </p>
                </div>
              </div>
            </div> */}

            <AlertProvider>
              <ContextThemeProvider>
                {/* <StylesProvider injectFirst> */}
                <CssBaseline />
                <NavBar clientName={clientName} projectName={projectName} />
                <div style={{ height: "calc(100% - 64px)" }}>
                  <BrowserRouter>
                    <Routes>
                      <Route path="/" element={<Homepage />} />
                      <Route path="/newflex" element={<Ainqaflexmon />} />
                      <Route path="/steps" element={<Stepform />} />
                      <Route path="/view/:id" element={<Viewpage />} />
                      <Route path="/edit" element={<Stepperedit />} />
                    </Routes>
                  </BrowserRouter>
                </div>
                {/* </StylesProvider> */}
              </ContextThemeProvider>
            </AlertProvider>
          </div>
        </>
      )}
    </>
  );
}

export default Home;
